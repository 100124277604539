<template>
  <Layout>
    <PageHeader :title="title" :items="items"> </PageHeader>
    <div class="row" id="page">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Title"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="d-flex justify-content-end align-items-center"
                  v-if="can('add-survey')"
                >
                  <svg
                    class="svg-inline--fa fa-table mx-2"
                    height="16px"
                    width="16px"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fa"
                    data-icon="table"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                    ></path>
                  </svg>
                  <router-link :to="{ name: 'add-survey' }" class="opt"
                    >Add Survey</router-link
                  >
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-can="'edit-survey'" v-slot:cell(edit)="row">
                  <router-link
                    :to="{ name: 'edit-survey', params: { id: row.item.id } }"
                  >
                    <i class="fa fa-edit edit-i"></i>
                  </router-link>
                </template>
                <template v-slot:cell(question)="row">
                  <b-button
                    class="btn btn-sm"
                    variant="primary"
                    @click="showModal(row.item.id)"
                    ref="btnShow"
                    :disabled="
                      row.item.google_form_link != null ||
                      form.google_form_link != ''
                    "
                  >
                    Import Question CSV
                  </b-button>
                </template>
                <template v-slot:cell(download_excel)="row">
                  <b-button
                    class="btn btn-sm"
                    variant="secondary"
                    ref="btnShow"
                    :disabled="
                      row.item.google_form_link != null ||
                      form.google_form_link != ''
                    "
                    @click="downloadReport(row.item.id)"
                  >
                    Download Report
                  </b-button>
                </template>
                <template v-slot:cell(show_survey_question)="row">
                  <router-link
                    :to="{
                      name: 'survey-question-list',
                      params: { id: row.item.id },
                    }"
                  >
                    <b-button
                      variant="info"
                      class="btn-sm"
                      :disabled="
                        row.item.google_form_link != null ||
                        form.google_form_link != ''
                      "
                      >Survey Question List
                    </b-button>
                  </router-link>
                </template>
                <template v-slot:cell(report)="row">
                  <router-link
                    :to="{
                      name: 'report-survey',
                      params: { id: row.item.id },
                    }"
                  >
                    <b-button
                      variant="success"
                      class="btn-sm"
                      :disabled="
                        row.item.google_form_link != null ||
                        form.google_form_link != ''
                      "
                      >Report
                    </b-button>
                  </router-link>
                </template>
                <template
                  v-can="'update-survey-status'"
                  v-slot:cell(is_active)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateStatus(row.item.id)"
                    :checked="row.item.is_active == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(delete)="row" v-can="'delete-survey'">
                  <div @click.prevent="deleteSurvey(row.item.id)">
                    <i class="mdi mdi-trash-can delete-i"></i>
                  </div>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="questionModal"
      title="Import Question CSV"
      @ok="handleQuestionSubmit"
      @cancel="closeQuestionModal"
      @hide="beforeModalHide"
      no-close-on-backdrop
      centered
      hide-header-close
      :ok-title="'Import'"
      cancel-title="Close"
      size="lg"
    >
      <form ref="form" class="row">
        <b-form-group class="col-12">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <label class="mb-0" label-for="input-csv-file"
              >Import Question CSV File <span style="color: red">*</span></label
            >
            <a
              href="/survey_questions.xlsx"
              download
              class="btn btn-primary btn-sm"
            >
              <i class="fa fa-download mr-1"></i> Download Sample File
            </a>
          </div>
          <b-form-file
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="input-csv-file"
            placeholder="Choose a file or drop it here..."
            ref="question_csv_file"
            @change="readFile($event, 'question_csv_file')"
          ></b-form-file>
        </b-form-group>
      </form>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import pageMixin from "../../../mixins/ModuleJs/survey";

export default {
  mixins: [MixinRequest, pageMixin],
  data() {
    return {
      title: "Survey Management",
      items: [
        {
          text: "Home",
          href: "/",
        },

        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  created() {
    this.fetchData();
  },
};
</script>
